import GameModel from '../model/GameModel';
import XXXDataParser from './XXXDataParser';

export default new class ServerManager {
  constructor() {
    this._initManagerParams = {
      developmentGetSessionPath: 'https://int.dev.onlyplay.net/integration/request_frame/?game=hotandspicy&no_redirect&no_op_launcher',
      cheatsParams:  ['force_reels', 'force_mini_reels'],
      baseData: {
        ver: '1.0'
      }
    };
  }

  async onInit() {
    window.OPWrapperService.serverManager.initManagerGameRequests(this._initManagerParams);
    await window.OPWrapperService.serverManager.sendGameInitRequest();
    const data = await this._sendRequest({}, 'config');
    GameModel.setNewData(XXXDataParser.parseInit(data));
  }

  async onStartSpin() {
    let request = {
      betIndex: GameModel.betIndex,
    };
    const data = await this._sendRequest(request, 'spin');
    GameModel.setNewData(XXXDataParser.parseSpin(data));
    return data;
  }
  async _sendRequest(data, id, urlPath = 'game_request') {
    data.id = id;
    return await window.OPWrapperService.serverManager.sendGameRequest(data, urlPath);
  }
}
